import React, { createContext, useEffect, useState } from "react";
import mqtt from "mqtt";
// import { useAuth } from '@/store';

export const MqttContext = createContext();

const MQTT_BROKER_URL = "wss://mqtt.3dvam.info"; // Replace with your MQTT broker URL

// Add any default options you want for the connection
const MQTT_OPTIONS = {
  protocolVersion: 5,
  username: "papinaga",
  password: "Cideng87(",
  reconnectPeriod: 1000,
  clean: true, // Clean session
  clientId: `mqtt_${Math.random().toString(16).slice(3)}`, // Unique client ID
};

export const MqttProvider = ({ children }) => {
  const [client, setClient] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  const [messagesTopicTicket, setMessagesTopicTicket] = useState([]);
  const [messagesTopicNotification, setMessagesTopicNotification] = useState(
    []
  );
  const [messagesTradeChart, setMessagesTradeChart] = useState([]);
  const [messagesTradeData, setMessagesTradeData] = useState([]);

  // Initialize MQTT connection on mount
  useEffect(() => {
    const mqttClient = mqtt.connect(MQTT_BROKER_URL, MQTT_OPTIONS);

    mqttClient.on("connect", () => {
      setIsConnected(true);
      console.log("Connected to MQTT broker");
    });

    mqttClient.on("message", (topik, message) => {
      console.log("🚀 ~ mqttClient.on ~ topik:", topik);
      const parsedMessage = JSON.parse(message.toString());
      console.log("🚀 ~ mqttClient.on ~ parsedMessage:", parsedMessage);
      if (topik.includes(`ticket`)) {
        setMessagesTopicTicket(parsedMessage);
      } else if (topik.includes(`notification`)) {
        setMessagesTopicNotification(parsedMessage);
      } else if (topik.includes("Tradechart")) {
        setMessagesTradeChart(parsedMessage);
      } else if (topik.includes("Tradedata")) {
        setMessagesTradeData(parsedMessage);
      }
    });

    mqttClient.on("error", (err) => {
      console.error("Connection error:", err);
      mqttClient.end();
    });

    setClient(mqttClient);

    // Clean up on unmount
    return () => {
      mqttClient.end();
    };
  }, []);

  const subscribeToTopic = (topic) => {
    if (client) {
      client.subscribe(topic, (err) => {
        if (!err) {
          console.log(`Subscribed to topic: ${topic}`);
        } else {
          console.error(`Subscription error: ${err}`);
        }
      });
    }
  };

  const unsubscribeFromTopic = (topic) => {
    if (client) {
      client.unsubscribe(topic, (err) => {
        if (!err) {
          console.log(`Unsubscribed from topic: ${topic}`);
        } else {
          console.error(`Unsubscribe error: ${err}`);
        }
      });
    }
  };

  const publishMessage = (topic, message) => {
    if (client) {
      client.publish(topic, message);
    }
  };

  return (
    <MqttContext.Provider
      value={{
        client,
        isConnected,
        messagesTopicNotification,
        messagesTopicTicket,
        messagesTradeChart,
        messagesTradeData,
        subscribeToTopic,
        publishMessage,
        unsubscribeFromTopic,
      }}
    >
      {children}
    </MqttContext.Provider>
  );
};
